import { type LocalePathFunction } from 'vue-i18n-routing';

const getContentCardConfig = (localePath: LocalePathFunction) => {
  // Promotion for private label on dog food categories
  const contentCardConfigPrivateLabel = {
    showForAttributes: [
      {
        name: 'categoriesPath',
        value: 'djurtyp/hund',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/hund/hundfoder',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/hund/hundfoder/farskfoder- ',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/hund/hundfoder/torrfoder',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/hund/hundfoder/veterinarfoder',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/hund/hundfoder/vatfoder',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/hund/hundfoder/vatfoder/farskfoder',
      },
    ],
    name: 'private-label',
    image: {
      desktop: 'private-label-desktop.webp',
      mobile: 'private-label-mobile.webp',
    },
    link: localePath({
      name: 'shop-brands-slug',
      params: { slug: 'firstvet' },
    }),
  };

  const contentCardConfigCatFoodConsultation = {
    showForAttributes: [
      {
        name: 'categoriesPath',
        value: 'djurtyp/katt/kattfoder/veterinarfoder',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/katt/kattfoder/torrfoder',
      },
      {
        name: 'categoriesPath',
        value: 'djurtyp/katt/kattfoder/vatfoder',
      },
    ],
    name: 'cat-food-consultation',
    image: {
      desktop: 'cat-food-consultation-desktop.webp',
      mobile: 'cat-food-consultation-mobile.webp',
    },
    link: localePath('/tjanster/veterinar/foder-och-halsa'),
  };

  return {
    sv: [contentCardConfigPrivateLabel, contentCardConfigCatFoodConsultation],
  };
};

export { getContentCardConfig };
